import { List } from '@/components/atoms/Audiobook'
import { AudiobookItem } from '@/components/organisms'
import { useConnectStates } from '@/contexts/connect'
import { useFavorite } from '@/contexts/favorite'
import Audiobook from '@/libs/Audiobook'

interface PageTargetContentsProps {
  audiobookList?:
    | Queries.findPageQuery['contentfulPage']['audiobookList'][0]['audiobooks']
    | Queries.findCampaignRequirementPurchaseQuery['contentfulCampaignRequirementPurchase']['purchaseAudiobookContent']
}
type AudiobookQuery = PageTargetContentsProps['audiobookList'][0]

const PageTargetContents = ({ audiobookList }: PageTargetContentsProps) => {
  const collectionIds = audiobookList?.map((audiobook: AudiobookQuery) => audiobook.collectionId)
  const { favorite } = useConnectStates([['favorite', { collectionIds }]])
  const { favoriteProps } = useFavorite()
  const { itemFavorite } = favoriteProps(favorite)

  return (
    <>
      {audiobookList?.length > 0 && (
        <List>
          {audiobookList.filter(Audiobook.available).map((audiobook: AudiobookQuery, i: number) => (
            <AudiobookItem
              key={i}
              {...{
                ...audiobook,
                discount: audiobook?.discount?.map((d) => ({ ...d, freeMember: null })),
              }}
              favorite={itemFavorite(audiobook.collectionId)}
            />
          ))}
        </List>
      )}
    </>
  )
}

export default PageTargetContents
