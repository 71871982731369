import { Box, Typography, type TypographyProps, styled } from '@mui/material'
import { useCallback, useMemo, useRef } from 'react'
import { animated, useSpring } from 'react-spring'

export interface SpringTextProps extends TypographyProps {}

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  textOverflow: 'clip',
})

const SpringText = ({ children, ...rest }: SpringTextProps) => {
  const ref = useRef<HTMLSpanElement>({
    offsetWidth: 0,
    scrollWidth: 0,
  } as HTMLSpanElement)

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const animation = async (next: any) => {
    await next({
      reset: true,
      from: { transform: 'translateX(0px)' },
    })
    await new Promise((resolve) => setTimeout(() => resolve(true), 3000))
    if (ref.current?.offsetWidth < ref.current?.scrollWidth) {
      const scrollWidth = ref.current.scrollWidth
      await next({
        reset: true,
        config: { duration: scrollWidth * 30 },
        from: { transform: 'translateX(0px)' },
        transform: `translateX(-${scrollWidth + 16}px)`,
      })
    }
    await animation(next)
  }

  const style = useSpring({
    from: { transform: 'translateX(0px)' },
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    to: useCallback(animation, [children]),
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const text = useMemo(
    () => (
      <Box overflow="hidden">
        <animated.div style={style}>
          <StyledTypography ref={ref} {...rest}>
            {children}
          </StyledTypography>
        </animated.div>
      </Box>
    ),
    [children],
  )

  return <>{text}</>
}

export default SpringText
