import { createStoreContext, useSelector } from '@/hooks/store'
import { type ReactNode, createContext, useContext } from 'react'

/** 試聴プレイヤーの全体で必要なプロパティを配る目的のコンポーネント */

const Context = createContext<AuditionContextProps>(undefined)
interface AuditionStore {
  collectionId: string
}

export const useAudition = () => useContext(Context)
interface AuditionContextProps {
  useAuditionDispach: () => {
    setCollectionId: (collectionId: string) => void
  }
  useCollectionId: () => string
}

interface AuditionProviderProps {
  children: ReactNode
}

export const AuditionProvider = ({ children }: AuditionProviderProps) => {
  const context = createStoreContext<AuditionStore>(() => ({
    collectionId: '',
  }))
  return (
    <Context.Provider
      value={{
        useAuditionDispach: () => ({
          setCollectionId: (collectionId: string) => context.dispatch((state) => ({ ...state, collectionId })),
        }),
        useCollectionId: () => useSelector(context, (state: AuditionStore) => state.collectionId),
      }}
    >
      {children}
    </Context.Provider>
  )
}
