import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => (
  <Box
    sx={{
      pt: 1,
      pb: 4,
      mb: { xs: 6, md: 0 },
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Typography variant="body1" color="textSecondary">
      &copy; 2021 DWANGO Co., Ltd.
    </Typography>
  </Box>
)

export default React.memo(Footer)
