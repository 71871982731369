import { useAudiobookInfo } from '@/hooks/audiobook'
import ListItem, { type ItemProps } from '.'

const ItemAsync = (props: ItemProps) => {
  const audiobook = useAudiobookInfo(props.collectionId, true)

  return audiobook.collectionId ? <ListItem {...{ ...props, ...audiobook }} /> : <></>
}

export default ItemAsync
