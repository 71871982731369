import { SignIn } from '@/components/SVG/Icons'
import { IconButton, type IconButtonProps } from '@mui/material'

export interface SignInIconButtonProps extends IconButtonProps {}

const SignInIconButton = (props: SignInIconButtonProps): JSX.Element => {
  return (
    <IconButton {...props} size="large">
      <SignIn fontSize="large" />
    </IconButton>
  )
}

export default SignInIconButton
