import { ResetIconButton, SearchBoxInput, SearchIconButton } from '@/components/atoms'
import { styled } from '@mui/material'
import { Box, Divider } from '@mui/material'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import type React from 'react'
import { useEffect, useRef } from 'react'
import type { SearchBoxProvided } from 'react-instantsearch-core'
import { connectSearchBox } from 'react-instantsearch-dom'

export interface SearchBoxProps extends Partial<SearchBoxProvided> {
  onChange: (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onReset: (event: React.SyntheticEvent<HTMLElement>) => void
  placeholder: string
}

const ResetIcon = styled(Box)({
  height: '2.2rem',
  width: '2.2rem',
  marginRight: '0.8rem',
})

const SearchBoxContent = styled(Box)({
  maxWidth: '25.6rem',
  height: '3.2rem',
  padding: '0',
  border: '1px solid #0000003A',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'row',

  '& > .searchInput': {
    flex: 1,
  },
})

const SearchBox = ({ currentRefinement, refine, onChange, onSubmit, onReset, placeholder }: SearchBoxProps) => {
  const { pathname, search } = useLocation()
  const buttonRef = useRef<HTMLButtonElement>()
  const handleOnChange = (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    refine(event.currentTarget.value)
    onChange(event)
  }

  const handleOnReset = (event: React.SyntheticEvent<HTMLElement>) => {
    refine('')
    onReset(event)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (pathname?.includes('/search')) {
      const { query } = queryString.parse(search)
      refine(query || '')
    }
    return () => {
      if (pathname?.includes('/search')) {
        buttonRef?.current?.focus()
      }
      refine('')
    }
  }, [pathname, search])

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault()
        // iOS/Androidでinputタグにフォーカスが残り続けてIMEが開いたままになるため、検索ボタンにフォーカスを移して閉じている。
        buttonRef?.current?.focus()
        return onSubmit(e)
      }}
    >
      <SearchBoxContent>
        <SearchBoxInput
          className="searchInput"
          role="search"
          autoFocus={false}
          color="secondary"
          type="text"
          value={currentRefinement}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
        <ResetIcon>
          {currentRefinement && (
            <ResetIconButton color="secondary" onMouseDown={handleOnReset} onTouchStart={handleOnReset} />
          )}
        </ResetIcon>
        <Box mx={0.4} my={0.1}>
          <Divider orientation="vertical" />
        </Box>
        <SearchIconButton ref={buttonRef} disableFocusRipple type="submit" />
      </SearchBoxContent>
    </form>
  )
}

export default connectSearchBox<SearchBoxProps>(SearchBox)
