import ErrorSnackbarWithButton from '@/components/atoms/ErrorSnackbarWithButton'
import type { CSSProperties } from 'react'

export interface PlayerErrorSnackbarProps {
  open: boolean
  style?: CSSProperties
}

const PlayerErrorSnackbar = ({ open, style }: PlayerErrorSnackbarProps) => {
  const buttonText = '再読み込みする'
  const clickHandler = () => {
    window.location.reload()
  }
  return (
    <ErrorSnackbarWithButton open={open} style={style} buttonText={buttonText} onClick={clickHandler}>
      再生に失敗しました。
      <br />
      ページを再読み込みし、もう一度再生してください。
    </ErrorSnackbarWithButton>
  )
}

export default PlayerErrorSnackbar
