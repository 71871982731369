import { createStoreContext, useSelector } from '@/hooks/store'
import { type ReactNode, createContext, useContext } from 'react'

const Context = createContext<AuditionContextProps>(undefined)

interface AuditionPlayerStore {
  seeking: boolean
  sliderValue: number
  paused: boolean
  loading: boolean
}

export const useAuditionPlayer = () => useContext(Context)

interface AuditionContextProps {
  useAuditionPlayerDispach: () => {
    setSeeking: (seeking: boolean) => void
    setSliderValue: (sliderValue: number) => void
    setPaused: (paused: boolean) => void
    setLoading: (loading: boolean) => void
  }
  useSeeking: () => boolean
  useSliderValue: () => number
  usePaused: () => boolean
  useLoading: () => boolean
}

interface AuditionPlayerProviderProps {
  children: ReactNode
}

export const AuditionPlayerProvider = ({ children }: AuditionPlayerProviderProps) => {
  const context = createStoreContext<AuditionPlayerStore>(() => ({
    seeking: false,
    sliderValue: 0,
    paused: true,
    loading: false,
  }))

  return (
    <Context.Provider
      value={{
        useAuditionPlayerDispach: () => ({
          setSeeking: (seeking: boolean) => context.dispatch((state) => ({ ...state, seeking })),
          setSliderValue: (sliderValue: number) => context.dispatch((state) => ({ ...state, sliderValue })),
          setPaused: (paused: boolean) => context.dispatch((state) => ({ ...state, paused })),
          setLoading: (loading: boolean) => context.dispatch((state) => ({ ...state, loading })),
        }),
        useSeeking: () => useSelector(context, (state: AuditionPlayerStore) => state.seeking),
        useSliderValue: () => useSelector(context, (state: AuditionPlayerStore) => state.sliderValue),
        usePaused: () => useSelector(context, (state: AuditionPlayerStore) => state.paused),
        useLoading: () => useSelector(context, (state: AuditionPlayerStore) => state.loading),
      }}
    >
      {children}
    </Context.Provider>
  )
}
