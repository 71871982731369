import { Typography, type TypographyProps, styled } from '@mui/material'

interface HeadingH2Props extends TypographyProps {}

const StyledH2 = styled(Typography)({
  padding: '2.4rem 1.6rem',
})

const HeadingH2 = ({ children }: HeadingH2Props) => (
  <StyledH2 variant="h2" align="center">
    {children}
  </StyledH2>
)

export default HeadingH2
