import theme from '@/theme'
import { Button, type ButtonProps } from '@mui/material'

interface DefaultButtonProps extends ButtonProps {}

const DefaultButton = ({ children, sx, ...rest }: DefaultButtonProps) => (
  <Button
    color="default"
    sx={{
      // 下記の css はだいたい有効になるが、優先順位は`下に書いたものほど強い` ので覚えておくこと
      '&.MuiButton-colorDefault': {
        color: theme.palette.text.primary,
        bgcolor: 'rgba(0,0,0,0.08)',
        '&:hover': {
          bgcolor: 'rgba(0,0,0,0.12)',
        },
      },
      '&.MuiButton-textDefault': {
        bgcolor: 'transparent',
        '&:hover': {
          bgcolor: 'rgba(0,0,0,0.04)',
        },
      },
      '&.MuiButton-outlinedDefault': {
        color: theme.palette.text.primary,
        bgcolor: 'transparent',
        borderColor: 'rgba(189,189,189)',
        '&:hover': {
          bgcolor: 'rgba(0,0,0,0.04)',
        },
      },
      ':disabled': {
        color: theme.palette.text.disabled,
        bgcolor: 'rgba(0,0,0,0.12)',
      },
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Button>
)

export default DefaultButton
