import { SearchFormHits, SearchFormResults } from '@/components/molecules'
import SearchBox from '@/components/organisms/Search/Box'
import { createSearchClient, suggestionSearchOptions } from '@/libs/Algolia'
import { Box, ClickAwayListener } from '@mui/material'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'

const searchIndexName = process.env.ALGOLIA_INDEX_NAME
const SUGGESTIONS_LIST_MAX = 5

const SearchForm = () => {
  const { search } = useLocation()
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [searchWord, setSearchWord] = useState('')

  const handleFocusIn = () => setShowSuggestions(true)
  const handleFocusOut = () => showSuggestions && setShowSuggestions(false)

  const handleChangeSearchBox = (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchWord(event.currentTarget.value || '')
    handleFocusIn()
  }
  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement, Event>) => {
    event.preventDefault()
    const query = `?query=${encodeURIComponent(searchWord)}`
    if (!['?query=', search].includes(query)) {
      handleFocusOut()
      navigate(`/search?query=${encodeURIComponent(searchWord)}`)
    }
    return false
  }
  const handleResetSearchBox = (_: React.SyntheticEvent<HTMLElement>) => {
    setSearchWord('')
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const { query } = queryString.parse(search)
    if (query !== searchWord) {
      setSearchWord((query as string) || '')
    }
    setShowSuggestions(false)
  }, [search])

  return (
    <InstantSearch indexName={searchIndexName} searchClient={createSearchClient()}>
      <Configure clickAnalytics hitsPerPage={SUGGESTIONS_LIST_MAX} {...suggestionSearchOptions} />
      <ClickAwayListener onClickAway={handleFocusOut}>
        <Box onFocus={handleFocusIn}>
          <SearchBox
            onSubmit={handleSubmit}
            placeholder="キーワードで検索"
            onChange={handleChangeSearchBox}
            onReset={handleResetSearchBox}
          />
          <Index indexName={searchIndexName}>
            <Box position="relative" style={{ display: showSuggestions ? 'block' : 'none' }}>
              <Box position="absolute" width="100%" onClick={handleFocusOut}>
                <SearchFormResults>
                  <SearchFormHits searchWord={searchWord} />
                </SearchFormResults>
              </Box>
            </Box>
          </Index>
        </Box>
      </ClickAwayListener>
    </InstantSearch>
  )
}

export default SearchForm
