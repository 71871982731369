import { SearchFormHitList, SearchFormHitListItem } from '@/components/atoms'
import Hit from '@/components/molecules/Search/Form/Hit'
import SearchLink from '@/components/molecules/Search/Form/Link'
import type { HitAudioBook } from '@/libs/Algolia'
import type { HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'

export interface HitsProps extends HitsProvided<HitAudioBook> {
  searchWord: string
}

const Hits = ({ hits, searchWord }: HitsProps) => (
  <SearchFormHitList className="hits">
    {hits.map((hit: HitAudioBook) => (
      <SearchFormHitListItem key={hit.objectID}>
        <Hit hit={hit} queryID={hit.__queryID} position={hit.__position} />
      </SearchFormHitListItem>
    ))}
    <SearchFormHitListItem key="searchword">
      <SearchLink searchWord={searchWord} />
    </SearchFormHitListItem>
  </SearchFormHitList>
)

export default connectHits<HitsProps, HitAudioBook>(Hits)
