import ErrorSnackbar from '@/components/atoms/ErrorSnackbar'
import type { SnackbarCloseReason } from '@mui/material'
import type React from 'react'

export interface AuditionErrorSnackbarProps {
  open: boolean
  onClose?: (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => void
}

const AuditionErrorSnackbar = ({ open, onClose }: AuditionErrorSnackbarProps) => {
  return (
    <ErrorSnackbar open={open} onClose={onClose}>
      再生に失敗しました。
      <br />
      通信環境をお確かめの上、もう一度再生してください。
    </ErrorSnackbar>
  )
}

export default AuditionErrorSnackbar
