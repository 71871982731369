import theme from '@/theme'
import { Box, Button, Slide, Snackbar, type SnackbarCloseReason, type SnackbarProps, styled } from '@mui/material'
import { Alert } from '@mui/material'
import type React from 'react'

export interface ErrorSnackbarWithButtonProps extends Omit<SnackbarProps, 'children' | 'onClose'> {
  children: React.ReactNode
  buttonText?: string

  onClose?: (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => void
  onClick: () => void
}

const ErrorAlert = styled(Alert)({
  backgroundColor: theme.palette.error.main,
  borderRadius: '4px',
  color: theme.palette.error.contrastText,
  '& svg': {
    fill: theme.palette.error.contrastText,
  },
})

const ErrorSnackbarWithButton = ({ children, buttonText, onClick, ...others }: ErrorSnackbarWithButtonProps) => (
  <Snackbar
    {...others}
    TransitionComponent={Slide}
    autoHideDuration={5000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Box bgcolor={theme.palette.error.main} borderRadius="4px">
      <ErrorAlert severity="error" onClose={others.onClose}>
        {children}
      </ErrorAlert>
      <Box
        bgcolor={theme.palette.error.main}
        borderRadius="4px"
        px="1.6rem"
        pt="0"
        pb=".6rem"
        display="flex"
        justifyContent="flex-end"
      >
        <Button size="small" color="secondary" onClick={onClick}>
          <Box fontWeight="bold">{buttonText || '閉じる'}</Box>
        </Button>
      </Box>
    </Box>
  </Snackbar>
)

export default ErrorSnackbarWithButton
