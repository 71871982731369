import { FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material'
import { Box, type IconButtonProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { useHydrated } from 'react-hydration-provider'
import { animated, useSpring } from 'react-spring'

interface FavoriteIconButtonProps {
  isFavorite: boolean
  sx?: IconButtonProps['sx']
}

// 本コンポーネントは書籍リスト(24px)、書籍詳細画面(22px)の2箇所から利用することに特化してプロパティを調整している
// ほかから利用する際はアニメーションの中心位置がズレたりする可能性があるため注意
const FavoriteIcon = ({ isFavorite, sx }: FavoriteIconButtonProps): JSX.Element => {
  const [animation, setAnimation] = useState(isFavorite)
  const hydrated = useHydrated()

  const { x } = useSpring({
    from: { x: 0 },
    x: isFavorite ? 1 : 0,
    config: { duration: isFavorite ? 400 : 100 },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!hydrated) return
    setAnimation(!animation)
  }, [isFavorite])

  return (
    <Box
      sx={{
        display: 'inherit',
      }}
    >
      {isFavorite ? (
        <animated.div
          style={{
            scale: x.to({
              range: [0, 0.25, 0.5, 0.75, 1],
              output: [0.8, 1.1, 0.9, 1.1, 1],
            }),
            display: 'inherit',
          }}
        >
          <FavoriteRounded
            fontSize="large"
            sx={{
              ...sx,
              fill: '#E52C76',
            }}
          />
        </animated.div>
      ) : (
        <>
          <FavoriteBorderRounded fontSize="large" sx={sx} />
          <animated.div
            style={{
              opacity: x,
              scale: x,
              position: 'absolute',
              height: '2.2rem',
              width: '2.2rem',
            }}
          >
            <FavoriteRounded
              fontSize="large"
              sx={{
                ...sx,
                fill: '#E52C76',
              }}
            />
          </animated.div>
        </>
      )}
    </Box>
  )
}

export default FavoriteIcon
