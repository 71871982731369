import { MenuRounded } from '@mui/icons-material'
import { IconButton, type IconButtonProps } from '@mui/material'

interface MenuIconButtonProps extends IconButtonProps {}

const MenuIconButton = (props: MenuIconButtonProps): JSX.Element => {
  return (
    <IconButton {...props} size="large">
      <MenuRounded fontSize="large" />
    </IconButton>
  )
}

export default MenuIconButton
