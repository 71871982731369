import { type ReactNode, createContext, useContext } from 'react'

import { useHydrated } from 'react-hydration-provider'
import { useWebPSupportCheck } from 'react-use-webp-support-check'

const WebPContext = createContext<WebPContextProps>(undefined)

export const useWebP = () => useContext(WebPContext)

export const WebPProvider = ({ children }: WebPProviderProps) => {
  const hydrated = useHydrated()
  const supportWebp = useWebPSupportCheck()

  const fm = () => {
    return supportWebp ? 'webp' : 'jpg'
  }

  const aspectRatio = (width: number, height: number) => {
    return width / height
  }

  const px = (
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
    width?: number,
    height?: number,
  ) => {
    const ratio = aspectRatio(coverArt.file.details.image.width, coverArt.file.details.image.height)
    if (width && height) {
      return width / height > ratio ? `${(width - height * ratio) / 2}px` : '0'
    }
    return ratio < 1 ? `${((1 - 1 * ratio) / 2) * 100}%` : '0'
  }

  const py = (
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
    width?: number,
    height?: number,
  ) => {
    const ratio = aspectRatio(coverArt.file.details.image.width, coverArt.file.details.image.height)
    if (width && height) {
      return width / height < ratio ? `${(height - width / ratio) / 2}px` : '0'
    }
    return ratio > 1 ? `${((1 - 1 / ratio) / 2) * 100}%` : '0'
  }

  const src = (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => {
    return `${coverArt.file.url}?w=${width}&h=${height}&q=50&fm=${fm()}`
  }

  const srcSet = (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => {
    return [1, 2, 3]
      .map((n) => {
        return `${coverArt.file.url}?w=${width * n}&h=${height * n}&q=50&fm=${fm()}${n > 1 ? ` ${n}x` : ''}`
      })
      .join(', ')
  }

  const headSrc = (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => {
    return `${coverArt.file.url}?w=${width}&h=${height}&q=${hydrated ? 90 : 50}&fm=${fm()}`
  }

  const headSrcSet = (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => {
    return hydrated
      ? [1, 2, 3]
          .map((n) => {
            return `${coverArt.file.url}?w=${width * n}&h=${height * n}&q=90&fm=${fm()}${n > 1 ? ` ${n}x` : ''}`
          })
          .join(', ')
      : ''
  }

  const calculatedHeight = (
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
    width?: number,
  ) => {
    const ratio = aspectRatio(coverArt.file.details.image.width, coverArt.file.details.image.height)
    return Math.round(width / ratio)
  }

  return (
    <WebPContext.Provider
      value={{
        fm,
        px,
        py,
        src,
        headSrc,
        headSrcSet,
        srcSet,
        calculatedHeight,
      }}
    >
      {children}
    </WebPContext.Provider>
  )
}

type WebPContextProps = {
  fm: () => string
  px: (
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
    width?: number,
    height?: number,
  ) => string
  py: (
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
    width?: number,
    height?: number,
  ) => string
  src: (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => string
  srcSet: (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => string
  headSrc: (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => string
  headSrcSet: (
    width: number,
    height: number,
    coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'],
  ) => string
  calculatedHeight: (coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt'], width?: number) => number
}

type WebPProviderProps = {
  children: ReactNode
}
