import { CenteringImg } from '@/components/atoms'
import { Box, Link, ListItemAvatar, ListItem as MuiListItem, type SxProps, Typography, styled } from '@mui/material'

const ShadowImg = styled('img')({
  borderRadius: '4px',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.32)',
})

const ShortCss: SxProps = {
  display: '-webkit-box',
  WebkitLineClamp: '2 !important',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}

export interface ItemProps {
  page: Queries.findContentfulPageListQuery['contentfulPageList']['pages'][0]
}

const ListItem = ({ page: { title, slug, shortDescription, headerImage } }: ItemProps) => (
  <MuiListItem
    sx={{
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'flex-start',
      p: 2,
    }}
    divider
  >
    <ListItemAvatar sx={{ width: 80 }}>
      <Link href={`/page/${slug}`}>
        {headerImage ? (
          <CenteringImg width={80} height={56} coverArt={headerImage} title={title} />
        ) : (
          <ShadowImg src="/assets/images/page/placeholder.svg" alt={title} width={80} height={56} />
        )}
      </Link>
    </ListItemAvatar>
    <Box sx={{ ml: 2 }}>
      <Link href={`/page/${slug}`} color="textPrimary">
        <Typography variant="h2" color="textPrimary" sx={{ mb: 1, ...ShortCss }}>
          {title}
        </Typography>
      </Link>

      <Typography color="textSecondary" sx={ShortCss}>
        {shortDescription}
      </Typography>
    </Box>
  </MuiListItem>
)

export default ListItem
