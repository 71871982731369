import { SvgIcon, type SvgIconProps } from '@mui/material'

interface BookmarkProps extends SvgIconProps {}

const Bookmark = (props: BookmarkProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M0,0H24V24H0Z" fill="none" />
    <path
      d="M19991,19493l-7,3,.01-16a2,2,0,0,1,1.988-2h10a2.006,2.006,0,0,1,2,2v16Zm5,0v-13h-10v13l5-2.18Zm-6-4v-2h-2v-2h2v-2h2v2h2v2h-2v2Z"
      transform="translate(-19979 -19475)"
    />
  </SvgIcon>
)

export default Bookmark
