import { sendEvent } from '@/libs/GoogleTagManager'
import React, { type SyntheticEvent } from 'react'

interface GoogleTagManagerEventProps {
  category: string

  action: string

  label?: string

  value?: number

  children: React.ReactNode
}

const GoogleTagManagerEvent = ({ category, action, label, value, children }: GoogleTagManagerEventProps) => (
  <>
    {React.Children.map(children, (child) => {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const childElement = child as React.ReactElement<any>
      return React.cloneElement(childElement, {
        onClick: (e: SyntheticEvent) => {
          sendEvent(action, label, category, value)
          childElement.props.onClick?.(e)
        },
      })
    })}
  </>
)

export default GoogleTagManagerEvent
