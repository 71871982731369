import { useRef, useSyncExternalStore } from 'react'

export type ContextType<T> = {
  state: T
  storeChanges: Set<() => void>
  dispatch: (callback: (state: T) => T) => void
  subscribe: (onStoreChange: () => void) => () => void
}

export const createStoreContext = <T>(initState: () => T) => {
  const context = useRef<ContextType<T>>({
    state: initState(),
    storeChanges: new Set(),
    dispatch: (callback) => {
      context.state = callback(context.state)
      for (const storeChange of context.storeChanges) {
        storeChange()
      }
    },
    subscribe: (onStoreChange) => {
      context.storeChanges.add(onStoreChange)
      return () => {
        context.storeChanges.delete(onStoreChange)
      }
    },
  }).current
  return context
}

export const useSelector = <T, R>(context: ContextType<T>, getSnapshot: (state: T) => R) =>
  useSyncExternalStore(
    context.subscribe,
    () => getSnapshot(context.state),
    () => getSnapshot(context.state),
  )
