import { List } from '@/components/atoms/Audiobook'
import { AudiobookItem } from '@/components/organisms'
import type { ItemRewardProps } from '@/components/organisms/Audiobook/Item'
import { useConnectStates } from '@/contexts/connect'
import { useFavorite } from '@/contexts/favorite'
import Audiobook from '@/libs/Audiobook'

type AudiobooksQuery =
  Queries.findSerialCodePageQuery['contentfulSerialCodePage']['reward']['audiobookList']['audiobooks']
type AudiobookQuery = AudiobooksQuery[0]
interface RewardTargetContentsProps {
  rewardOption: ItemRewardProps
  audiobooks?: AudiobooksQuery
}

const RewardTargetContents = ({ audiobooks, rewardOption }: RewardTargetContentsProps) => {
  const collectionIds = audiobooks?.map((audiobook: AudiobookQuery) => audiobook.collectionId) || []
  const { favorite } = useConnectStates([['favorite', { collectionIds }]])
  const { favoriteProps } = useFavorite()
  const { itemFavorite } = favoriteProps(favorite)

  return (
    <>
      {audiobooks?.length > 0 && (
        <List>
          {audiobooks.filter(Audiobook.available).map((audiobook: AudiobookQuery, i: number) => (
            <AudiobookItem
              key={i}
              {...{
                ...audiobook,
                discount: audiobook?.discount?.map((d) => ({ ...d, freeMember: null })),
              }}
              reward={rewardOption}
              favorite={itemFavorite(audiobook.collectionId)}
            />
          ))}
        </List>
      )}
    </>
  )
}

export default RewardTargetContents
