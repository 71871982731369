import Audio, { type AudioHandles } from '@/components/organisms/Audiobook/Player/Audio'
import type { Chapter } from '@/components/organisms/Audiobook/Player/Drawer/Chapters'
import { REPEAT_STATE } from '@/components/organisms/Audiobook/Player/Repeat'
import { createStoreContext, useSelector } from '@/hooks/store'
import { type ReactNode, createContext, useContext, useRef } from 'react'

const Context = createContext<AudioContextProps>(undefined)

interface AudioStore {
  audiobook: Audiobook
  loading: boolean
  sliderValue: number
  seeking: boolean
  repeat: REPEAT_STATE
  chapter: Chapter
  chapterIndex: number
  playbackRate: number
  volume: number
  hasError: boolean
}

export const useAudio = () => useContext(Context)

export const AudioProvider = ({ children }: AudioProviderProps) => {
  const context = createStoreContext<AudioStore>(() => ({
    audiobook: undefined,
    loading: false,
    sliderValue: 0,
    seeking: false,
    repeat: REPEAT_STATE.OFF,
    chapter: {
      sequence: '',
      title: '',
      duration: '',
      resourceId: '',
    } as Chapter,
    chapterIndex: 0,
    playbackRate: 1,
    volume: 1,
    hasError: false,
  }))
  const audioRef = useRef<AudioHandles>()

  return (
    <Context.Provider
      value={{
        useAudioDispach: () => ({
          setAudiobook: (audiobook: Audiobook) => context.dispatch((state) => ({ ...state, audiobook })),
          setLoading: (loading: boolean) => context.dispatch((state) => ({ ...state, loading })),
          setSliderValue: (sliderValue: number) => context.dispatch((state) => ({ ...state, sliderValue })),
          setSeeking: (seeking: boolean) => context.dispatch((state) => ({ ...state, seeking })),
          setRepeat: (repeat: REPEAT_STATE) => context.dispatch((state) => ({ ...state, repeat })),
          setChapter: (chapter: Chapter) => context.dispatch((state) => ({ ...state, chapter })),
          setChapterIndex: (chapterIndex: number) => context.dispatch((state) => ({ ...state, chapterIndex })),
          setPlaybackRate: (playbackRate: number) => context.dispatch((state) => ({ ...state, playbackRate })),
          setVolume: (volume: number) => context.dispatch((state) => ({ ...state, volume })),
          setHasError: (hasError: boolean) => context.dispatch((state) => ({ ...state, hasError })),
        }),
        useAudiobook: () => useSelector(context, (state: AudioStore) => state.audiobook),
        useLoading: () => useSelector(context, (state: AudioStore) => state.loading),
        useSliderValue: () => useSelector(context, (state: AudioStore) => state.sliderValue),
        useSeeking: () => useSelector(context, (state: AudioStore) => state.seeking),
        useRepeat: () => useSelector(context, (state: AudioStore) => state.repeat),
        useChapter: () => useSelector(context, (state: AudioStore) => state.chapter),
        useChapterIndex: () => useSelector(context, (state: AudioStore) => state.chapterIndex),
        usePlaybackRate: () => useSelector(context, (state: AudioStore) => state.playbackRate),
        useVolume: () => useSelector(context, (state: AudioStore) => state.volume),
        useHasError: () => useSelector(context, (state: AudioStore) => state.hasError),

        audio: (newAudiobook?: Audiobook) => audioRef.current?.audio(newAudiobook) ?? ({} as HTMLAudioElement),
        play: async () => audioRef.current?.play(),
        stop: () => audioRef.current?.stop(),
        replay: () => audioRef.current?.replay(),
        forword: () => audioRef.current?.forword(),
        prev: () => audioRef.current?.prev(),
        next: () => audioRef.current?.next(),
        selectChapter: async (chapter: Chapter, play?: boolean) => audioRef.current?.selectChapter(chapter, play),
        setVolume: (volume: number) => audioRef.current?.setVolume(volume),
        setPlaybackRate: (playbackRate: number) => audioRef.current?.setPlaybackRate(playbackRate),
      }}
    >
      {children}
      <Audio ref={audioRef} />
    </Context.Provider>
  )
}

export const setMediaMetadata = ({
  title,
  authors,
  album,
  url,
  contentType,
}: {
  title: string
  authors: Queries.audiobookInfoQuery['contentfulAudiobook']['authors']
  album: string
  url: string
  contentType: string
}) => {
  if (!('mediaSession' in navigator)) {
    return
  }

  navigator.mediaSession.metadata = new MediaMetadata({
    title,
    artist: authors
      ?.map((author) => author.names.find((name) => name.languageAndScriptCode === 'ja-Hani').text)
      .join(' '),
    album,
    artwork:
      url &&
      [1024, 96, 128, 192, 256, 384, 512].map((size) => ({
        src: `${url}?w=${size}&h=${size}&q=50`,
        sizes: `${size}x${size}`,
        type: contentType,
      })),
  })
}

export type Audiobook = Pick<
  Queries.audiobookPlayerQuery['contentfulAudiobook'],
  'title' | 'collectionId' | 'authors' | 'coverArt'
> & {
  chapterArray: Chapter[]
}

interface AudioContextProps {
  useAudioDispach: () => {
    setAudiobook: (audiobook: Audiobook) => void
    setLoading: (loading: boolean) => void
    setSliderValue: (sliderValue: number) => void
    setSeeking: (seeking: boolean) => void
    setRepeat: (repeat: REPEAT_STATE) => void
    setChapter: (chapter: Chapter) => void
    setChapterIndex: (chapterIndex: number) => void
    setPlaybackRate: (playbackRate: number) => void
    setVolume: (volume: number) => void
    setHasError: (hasError: boolean) => void
  }
  useAudiobook: () => Audiobook
  useLoading: () => boolean
  useSliderValue: () => number
  useSeeking: () => boolean
  useRepeat: () => REPEAT_STATE
  useChapter: () => Chapter
  useChapterIndex: () => number
  usePlaybackRate: () => number
  useVolume: () => number
  useHasError: () => boolean

  audio: (newAudiobook?: Audiobook) => HTMLAudioElement
  play: () => Promise<void>
  stop: () => void
  replay: () => void
  forword: () => void
  prev: () => void
  next: () => void
  selectChapter: (chapter: Chapter, play?: boolean) => Promise<void>
  setVolume: (volume: number) => void
  setPlaybackRate: (playbackRate: number) => void
}

interface AudioProviderProps {
  children: ReactNode
}
