import { SvgIcon, type SvgIconProps } from '@mui/material'

interface SignOutProps extends SvgIconProps {}

const SignOut = (props: SignOutProps) => (
  <SvgIcon {...props}>
    <path
      d="M17845,19882a1,1,0,0,1,0-2h7v-14h-7a1,1,0,0,1,0-2h7a2.009,2.009,0,0,1,2,2v14a2.011,2.011,0,0,1-2,2Zm-7.115-4.7-3.592-3.592a.993.993,0,0,1,0-1.408l3.592-3.6a.989.989,0,0,1,1.4,1.4l-1.9,1.9h9.2a1,1,0,0,1,0,2h-9.2l1.9,1.9a.99.99,0,1,1-1.4,1.4Z"
      transform="translate(-17834 -19863.998)"
    />
  </SvgIcon>
)

export default SignOut
