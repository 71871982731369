import { Chip } from '@mui/material'

export type RewardChipProps = {}

const RewardChip = (_: RewardChipProps) => (
  <Chip
    color="primary"
    sx={{
      bgcolor: '#54C244',
    }}
    label="特典"
    size="small"
  />
)

export default RewardChip
