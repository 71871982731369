import { Typography, type TypographyProps, styled } from '@mui/material'

interface HeadingH4Props extends TypographyProps {}

const StyledH4 = styled(Typography)({
  padding: '1.6rem 0',
})

const HeadingH4 = ({ children }: HeadingH4Props) => <StyledH4 variant="h4">{children}</StyledH4>

export default HeadingH4
