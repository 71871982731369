import theme from '@/theme'
import { type ListItemAvatarProps, ListItemAvatar as MuiListItemAvatar } from '@mui/material'

interface ItemAvatarProps extends ListItemAvatarProps {}

const ListItemAvatar = ({ children, ...rest }: ItemAvatarProps) => (
  <MuiListItemAvatar
    sx={{
      mr: 2,
      [theme.breakpoints.up('md')]: {
        p: 0,
        m: 0,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.06)',
      },
    }}
    {...rest}
  >
    {children}
  </MuiListItemAvatar>
)

export default ListItemAvatar
