import { Typography, styled } from '@mui/material'

const HitText = styled(Typography)({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
  whiteSpace: 'nowrap',
  height: '2rem',
  lineHeight: 1.5,

  '&.author': {
    fontSize: '1rem',
  },
})

export default HitText
