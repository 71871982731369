import { DrawerMenu, Footer, Header } from '@/components/organisms'
import { AppProvider } from '@/contexts/app'
import { AudioProvider } from '@/contexts/audio'
// import { AudioProvider, useAudio } from '@/contexts/audio'
// import MiniPlayer from '@/components/organisms/Audiobook/Player/MiniPlayer'
import { AuditionProvider } from '@/contexts/audition'
import { ConnectProvider } from '@/contexts/connect'
import { FavoriteProvider } from '@/contexts/favorite'
import { WebPProvider } from '@/contexts/webp'
import theme, { headerHeight, drawerWidth } from '@/theme'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import type { PageProps } from 'gatsby'
import React from 'react'
import { HydrationProvider } from 'react-hydration-provider'
import { SWRConfig } from 'swr'

interface AppProps {
  children: React.ReactNode
}

export const App = ({ children }: AppProps) => {
  // const { audiobook } = useAudio()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <DrawerMenu />
      <Box
        sx={{
          pt: headerHeight,
          ml: { md: drawerWidth.md },
        }}
      >
        {children}
        {/* {Boolean(audiobook) && <MiniPlayer />} */}
        <Footer />
      </Box>
    </ThemeProvider>
  )
}

interface WrapPageElementProps {
  element: React.ReactNode
  props: PageProps
}

const activeEnv = process.env.GATSBY_ACTIVE_ENV
if (activeEnv !== 'local') {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: activeEnv,
  })
}
const ErrorBoundary = activeEnv !== 'local' ? Bugsnag.getPlugin('react').createErrorBoundary(React) : React.Fragment

export const wrapPageElement = ({ element, props }: WrapPageElementProps) => (
  <ErrorBoundary>
    <SWRConfig
      value={{
        revalidateOnMount: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
      }}
    >
      <HydrationProvider>
        <WebPProvider>
          <AppProvider>
            <ConnectProvider {...props}>
              <AudioProvider>
                <AuditionProvider {...props}>
                  <App>
                    <FavoriteProvider {...props}>{element}</FavoriteProvider>
                  </App>
                </AuditionProvider>
              </AudioProvider>
            </ConnectProvider>
          </AppProvider>
        </WebPProvider>
      </HydrationProvider>
    </SWRConfig>
  </ErrorBoundary>
)
