import { useAudiobookInfo } from '@/hooks/audiobook'
import SlideItem from '.'

interface SlideItemAsyncProps {
  collectionId: string
}

const SlideItemAsync = ({ collectionId }: SlideItemAsyncProps) => {
  const audiobook = useAudiobookInfo(collectionId, true)

  return audiobook.collectionId ? <SlideItem {...audiobook} /> : <></>
}

export default SlideItemAsync
