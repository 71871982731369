import { DiscountChip } from '@/components/atoms/Chips'
import theme from '@/theme'
import { Box, Typography } from '@mui/material'

export interface DiscountHeaderProps {
  discountLabel: string
  discountEndDateTime: string
}

const DiscountHeader = ({ discountLabel, discountEndDateTime }: DiscountHeaderProps) => (
  <Box px={2} py={1.5} bgcolor={theme.palette.secondary.main} display="flex">
    <DiscountChip discountLabel={discountLabel} />
    <Box ml={1}>
      <Typography>{discountEndDateTime}</Typography>
    </Box>
  </Box>
)

export default DiscountHeader
