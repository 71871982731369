import { useWebP } from '@/contexts/webp'
import { Box, styled } from '@mui/material'

const ShadowImg = styled('img')({
  borderRadius: '4px',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.32)',
  width: '100%',
  height: '100%',
})

export interface CenteringImgProps {
  width: number
  height: number
  coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt']
  title: string
  loading?: 'eager' | 'lazy'
}
const CenteringImg = ({ width, height, coverArt, title, loading = 'eager' }: CenteringImgProps) => {
  const { px, py, src, srcSet } = useWebP()
  if (!coverArt) {
    return <></>
  }
  return (
    <Box
      sx={{
        width,
        height,
        px: px(coverArt, width, height),
        py: py(coverArt, width, height),
      }}
    >
      <ShadowImg
        loading={loading}
        src={src(width, height, coverArt)}
        srcSet={srcSet(width, height, coverArt)}
        alt={title}
      />
    </Box>
  )
}

export default CenteringImg
