import HeaderTitleSP from '@/components/SVG/HeaderTitleSP'
import { Box, Typography } from '@mui/material'

const LogoSP = () => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <HeaderTitleSP sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.87)' }} />
    <Typography
      sx={{
        fontSize: '1rem',
        pl: 1.5,
        lineHeight: '1rem',
        color: 'rgba(0,0,0,0.76)',
      }}
    >
      オーディオブック聴くなら
      <br />
      ドワンゴジェイピー!
    </Typography>
  </Box>
)

export default LogoSP
