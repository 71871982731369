import { CenteringImg, DefaultButton } from '@/components/atoms'
import {
  Box,
  Collapse,
  Link,
  ListItemAvatar,
  ListItem as MuiListItem,
  Paper,
  type SxProps,
  type Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import dayjs from 'dayjs'

const ShadowImg = styled('img')({
  borderRadius: '4px',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.32)',
})

export interface ItemProps {
  campaign: Queries.audiobookDetailQuery['contentfulAudiobook']['campaignrequirementpurchase'][0]
  sx?: SxProps
  in?: boolean
}

const ListItem = ({
  campaign: { campaignTitle, slug, shortDescription, headerImage, startDateTime, endDateTime },
  sx,
  in: inProp,
}: ItemProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Paper sx={{ ...sx, display: !inProp && 'none' }} elevation={isMobile ? 0 : 1}>
      <Collapse in={inProp}>
        <MuiListItem sx={{ display: 'flex', alignItems: 'flex-start', p: 2 }} divider={isMobile}>
          <ListItemAvatar sx={{ width: isMobile ? 80 : 146 }}>
            <Link href={`/campaign/purchase/${slug}`}>
              {headerImage ? (
                <CenteringImg
                  width={isMobile ? 80 : 146}
                  height={isMobile ? 56 : 103}
                  coverArt={headerImage}
                  title={campaignTitle}
                />
              ) : (
                <ShadowImg
                  src="/assets/images/page/placeholder.svg"
                  alt={campaignTitle}
                  width={isMobile ? 80 : 146}
                  height={isMobile ? 56 : 103}
                />
              )}
            </Link>
          </ListItemAvatar>
          <Box sx={{ ml: 2 }}>
            <Link href={`/campaign/purchase/${slug}`} color="textPrimary">
              <Typography variant="h2" color="textPrimary" sx={{ mb: 1 }}>
                {campaignTitle}
              </Typography>
            </Link>

            {shortDescription?.shortDescription && (
              <Typography color="textSecondary" sx={{ mb: 1.5 }}>
                {shortDescription.shortDescription}
              </Typography>
            )}
            <Box sx={{ display: 'flex', mb: 2 }}>
              <Typography variant="body2" color="textPrimary" sx={{ width: '4rem', mr: 1 }}>
                期間:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {`${dayjs(startDateTime).format('YYYY/MM/DD')} - ${dayjs(endDateTime).format('YYYY/MM/DD')}`}
              </Typography>
            </Box>
            <DefaultButton variant="contained" href={`/campaign/purchase/${slug}`} sx={{ fontWeight: 'bold' }}>
              詳細を見る
            </DefaultButton>
          </Box>
        </MuiListItem>
      </Collapse>
    </Paper>
  )
}

export default ListItem
