import { AddPointIconButton, DefaultButton, MenuIconButton, SignInIconButton } from '@/components/atoms'
import {
  AppBar,
  type AppBarProps,
  Box,
  Collapse,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import { useMemo } from 'react'

import { AddPoint, SignIn } from '@/components/SVG/Icons'
import LogoPC from '@/components/molecules/Logo/LogoPC'
import LogoSP from '@/components/molecules/Logo/LogoSP'
import SearchForm from '@/components/organisms/Search/Form'
import { useApp } from '@/contexts/app'
import { useConnect } from '@/contexts/connect'
import { FavoriteRounded } from '@mui/icons-material'
import { navigate } from 'gatsby'

export interface HeaderProps extends AppBarProps {}

const Header = ({ ...props }: HeaderProps) => {
  const { useAppDispach } = useApp()
  const { setDrawerOpen } = useAppDispach()
  const { hasCookie } = useConnect()
  const scrollTrigger = useScrollTrigger()
  const hasCookieLocal = hasCookie()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  return useMemo(() => {
    return (
      <AppBar color="inherit" {...props}>
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <Collapse in={!scrollTrigger}>
            <Box pt={1.5} px={2} mr={1.25} pb={0.5}>
              <Link href="/" underline="none">
                <LogoSP />
              </Link>
            </Box>
          </Collapse>
          <Toolbar
            sx={{
              height: { xs: '4.8rem' },
              minHeight: { xs: '4.8rem' },
              px: { xs: 2 },
            }}
          >
            <Box ml={-0.75} minWidth="11.4rem">
              <MenuIconButton onClick={() => setDrawerOpen(true)} />
              {hasCookieLocal ? (
                <>
                  <AddPointIconButton onClick={() => navigate('/information/purchase_points')} />
                  <IconButton onClick={() => navigate('/favorite')}>
                    <FavoriteRounded fontSize="large" />
                  </IconButton>{' '}
                </>
              ) : (
                <SignInIconButton onClick={() => navigate('/signin')} />
              )}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
              <SearchForm />
            </Box>
          </Toolbar>
        </Box>

        <Toolbar sx={{ display: { xs: 'none', md: 'flex' }, p: { md: 2 } }}>
          <Box sx={{ mr: 4, px: '0.5rem' }}>
            <Link href="/" underline="none">
              <LogoPC />
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                mb: 0.5,
                height: '2.4rem',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '1.2rem', color: 'rgba(0,0,0,0.76)' }}>
                  オーディオブック聴くならドワンゴジェイピー!
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SearchForm />
              <Box>
                {hasCookieLocal ? (
                  <>
                    <DefaultButton startIcon={<AddPoint fontSize="large" />} href="/information/purchase_points">
                      ポイント追加
                    </DefaultButton>
                    <DefaultButton startIcon={<FavoriteRounded fontSize="large" />} href="/favorite" sx={{ ml: 1.5 }}>
                      お気に入り
                    </DefaultButton>
                  </>
                ) : (
                  <DefaultButton startIcon={<SignIn fontSize="large" />} href="/signin">
                    ログイン / アカウント作成
                  </DefaultButton>
                )}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    )
  }, [hasCookieLocal])
}

export default Header
