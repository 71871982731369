import { CloseRounded } from '@mui/icons-material'
import type {} from '@mui/lab/themeAugmentation'
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface PaletteOptions {
    default?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true
  }
}

const { palette, breakpoints } = createTheme({
  palette: {
    primary: {
      main: '#009fde',
      light: '#3bc5cb',
      dark: '#006f9b',
      contrastText: '#ffffff',
    },
    // TODO: 再生・試聴ボタンに利用している。変更の可能性あり。
    secondary: {
      main: '#ffcb3d',
      light: '#ffd563',
      dark: '#b28e2a',
      contrastText: '#ffffff',
    },
    default: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    background: {
      default: '#ffffff',
    },

    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 488,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
})

const theme = createTheme({
  palette: palette,
  breakpoints: breakpoints,
  typography: {
    fontFamily: '"Noto Sans JP", sans-serif',
    h1: {
      fontSize: '2.4rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: palette.text.primary,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: palette.text.primary,
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: palette.text.primary,
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: palette.text.primary,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: palette.text.primary,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: palette.text.primary,
    },
    body1: {
      letterSpacing: 0,
      fontSize: '1.6rem',
      color: palette.text.primary,
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: 1.5,
      color: palette.text.primary,
    },
    button: {
      textTransform: 'none',
      fontSize: '1.2rem',
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: 1.5,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          minHeight: '100%',
          fontSize: '10px',
        },
        body: {
          margin: 0,
          fontSize: '1.4rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          minWidth: '8.8rem',
          height: '3.2rem',
          borderRadius: '0.4rem',
          lineHeight: 1.5,
        },
        outlined: {
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
        contained: {
          boxShadow: 'none !important',
        },
        sizeSmall: {
          minWidth: '6.1rem',
          height: '2.7rem',
          fontSize: '1.3rem',
        },
        sizeLarge: {
          minWidth: '9.1rem',
          height: '3.8rem',
          fontSize: '1.5rem',
          fontWeight: 'bold',
        },
        colorInherit: {
          color: palette.text.primary,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          borderColor: '#bebebe',
        },
        labelSmall: {
          fontSize: '1.3rem',
        },
      },
    },
    MuiList: {
      defaultProps: {
        component: 'div',
      },
    },
    MuiListItem: {
      defaultProps: {
        component: 'div',
      },
      styleOverrides: {
        button: {
          fontSize: '1.6rem',
          color: palette.text.primary,
        },
        root: {
          '&.Mui-selected': { backgroundColor: '#e0e0e0' },
        },
        dense: {
          backgroundColor: '#e0e0e0',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          '& > svg': {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          padding: '1.6rem 1.6rem 0',
          '&:last-of-type': {
            paddingBottom: '1.6rem',
          },
        },
        separator: {
          '& > svg': {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: '2.4rem',
        },
        root: {
          fontSize: '2rem',
        },
        colorSecondary: {
          color: palette.text.secondary,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0.6rem',
        },
        colorPrimary: {
          '& > svg': {
            color: palette.primary.main,
          },
        },
        colorSecondary: {
          '& > svg': {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '2.4rem',
          height: '2.4rem',
        },
        text: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        icon: {
          fontSize: '2rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiStepper: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: '48.8rem',
          padding: '2.4rem',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            fontWeight: 'bold',
            color: palette.text.primary,
          },
          color: 'rgba(0, 0, 0, 0.60)',
        },
        alternativeLabel: {
          fontWeight: 'normal',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
          height: '2.3rem',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          left: '1.6rem',
          right: '1.6rem',
        },
        anchorOriginBottomCenter: {
          bottom: '1.6rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: 'center',
        },
        action: {
          alignItems: 'center',
        },
        filledWarning: {
          backgroundColor: '#FF9800',
        },
      },
      defaultProps: {
        components: {
          CloseIcon: CloseRounded,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
})

export default theme

export const headerHeight = { xs: '9.6rem', md: '9.2rem' }

export const drawerWidth = { xs: '28rem', md: '25.2rem' }

export const containerWidth = '48.8rem'
