import { HeadingH1, HeadingH2 } from '@/components/atoms'
import AudiobookSlideList from '@/components/organisms/Audiobook/SlideList'
import { containerWidth } from '@/theme'
import { Box, Typography, styled } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'

const InformationContent = {
  error: (_: string) => ({
    heading: '',
    title: '',
    content: (
      <>
        現在、本サービスのコンテンツにアクセスすることができません。
        <br />
        お手数ですが、しばらく時間をおいてからもう一度アクセスしてください。
      </>
    ),
  }),
  noquery: (_: string) => ({
    heading: 'キーワードが入力されていません',
    title: '検索キーワードを入力してください',
    content: <>入力したキーワードにお間違えはありませんか？ 漢字やつづりが分からない時は、ひらがなの検索もできます。</>,
  }),
  notfound: (keyword: string) => ({
    heading: `"${keyword}"の検索結果`,
    title: `”${keyword}”に関する情報が見つからないようです。`,
    content: <>入力したキーワードにお間違えはありませんか？ 漢字やつづりが分からない時は、ひらがなの検索もできます。</>,
  }),
}

type InformationContentKey = keyof typeof InformationContent

export interface InformationProps {
  type: InformationContentKey
  keyword?: string
}

const StyledTitle = styled(Typography)({
  padding: '2.4rem 1.6rem',
})

const Information = ({ type, keyword = '' }: InformationProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query findSearchInformationRecommendedBooks {
      contentfulAudiobookList(slug: { eq: "staff-recommend" }) {
        title
        audiobooks {
          collectionId
          title
          authors {
            names {
              text
              languageAndScriptCode
            }
          }
          coverArt {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
              contentType
            }
          }
          campaignrequirementpurchase {
            slug
            startDateTime
            endDateTime
          }
          startDateTime
          endDateTime
          importedDateTime
          discount {
            label
            price
            endDateTime
            startDateTime
          }
        }
      }
    }
  `)

  const { heading, title, content } = InformationContent[type](keyword)

  return (
    <>
      {heading && <HeadingH1>{heading}</HeadingH1>}

      <Box textAlign="center" mb={3}>
        {title && <StyledTitle variant="h2">{title}</StyledTitle>}
        <Box m="auto" px={2} py={3} maxWidth={containerWidth}>
          <Typography variant="body1" color="textSecondary">
            {content}
          </Typography>
        </Box>
      </Box>
      <Box textAlign="center" mb={5}>
        <HeadingH2>{data.contentfulAudiobookList.title}</HeadingH2>
        <AudiobookSlideList books={data.contentfulAudiobookList.audiobooks} />
      </Box>
    </>
  )
}

export default Information
