import { CenteringImg } from '@/components/atoms'
import { ListItemAvatar, ListWrap } from '@/components/atoms/Audiobook'
import { Box, Link, type SxProps, Typography, styled } from '@mui/material'

const ShadowImg = styled('img')({
  borderRadius: '4px',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.32)',
  width: '204px',
  height: '142px',
  marginTop: 'calc((236px - 142px - 32px) / 2)',
})

const ShortCss: SxProps = {
  display: '-webkit-box',
  WebkitLineClamp: '2 !important',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}

export interface ItemProps {
  page: Queries.findContentfulPageListQuery['contentfulPageList']['pages'][0]
}

const ListItemResponsive = ({ page: { title, slug, shortDescription, headerImage } }: ItemProps) => (
  <ListWrap>
    <ListItemAvatar>
      <Link href={`/page/${slug}`} sx={{ width: '100%' }}>
        <Box height={236} py={2} display="flex" justifyContent="center">
          {headerImage ? (
            <CenteringImg width={204} height={204} coverArt={headerImage} title={title} />
          ) : (
            <ShadowImg src="/assets/images/page/placeholder.svg" alt={title} />
          )}
        </Box>
      </Link>
    </ListItemAvatar>
    <Box sx={{ p: 2, pb: 0 }}>
      <Link href={`/page/${slug}`} color="textPrimary">
        <Typography variant="h2" color="textPrimary" sx={{ mb: 1, ...ShortCss }}>
          {title}
        </Typography>
      </Link>

      <Typography color="textSecondary" sx={ShortCss}>
        {shortDescription}
      </Typography>
    </Box>
  </ListWrap>
)

export default ListItemResponsive
