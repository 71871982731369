import { BalloonBox } from '@/components/atoms'
import { Typography, styled } from '@mui/material'

const BalloonCaption = styled(Typography)({
  overflowWrap: 'normal',
})

export interface SpeechBallonProps {
  remainingQuantity: number
}

const SpeechBalloon = ({ remainingQuantity }: SpeechBallonProps) => {
  return (
    <BalloonBox>
      <Typography variant="body1" color="primary" component="span">
        あと
      </Typography>
      <Typography variant="h3" color="primary" component="span">
        &quot;{remainingQuantity}個 &quot;
      </Typography>
      <br />
      <BalloonCaption variant="caption" color="primary">
        対象コンテンツを購入すると応募できます！
      </BalloonCaption>
    </BalloonBox>
  )
}

export default SpeechBalloon
