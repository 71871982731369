import { Facebook, Line, Twitter } from '@/components/SVG/Icons'
import { Box, IconButton, styled } from '@mui/material'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { FacebookShareButton, LineShareButton, TwitterShareButton } from 'react-share'

interface SnsLinkBoxProps {
  title?: string
  url?: string
}

const StyledTwitterShareButton = styled(TwitterShareButton)({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
  },
})

const StyledFacebookButton = styled(FacebookShareButton)({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
  },
})

const StyledLineShareButton = styled(LineShareButton)({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
  },
})

const query = graphql`
  query SnsLinkBoxQuery {
    site {
      siteMetadata {
        defaultTitle
        titleTemplate
      }
    }
  }
`

const SnsLinkBox = ({ title, url }: SnsLinkBoxProps) => {
  const {
    site: {
      siteMetadata: { defaultTitle },
    },
  } = useStaticQuery(query)

  const { href } = useLocation()
  const shareTitle = title ?? defaultTitle

  return (
    <Box
      sx={{
        '& > *': {
          width: '4.8rem',
          height: '4.8rem',
          '&:not(:first-of-type)': {
            marginLeft: '0.8rem',
          },
        },
      }}
      p={1}
    >
      <IconButton
        component={StyledTwitterShareButton}
        url={url || href}
        title={shareTitle}
        hashtags={['ドワンゴジェイピーオーディオブック']}
        via="ListenGo_info"
      >
        <Twitter fontSize="large" />
      </IconButton>
      <IconButton component={StyledFacebookButton} url={url || href} title={shareTitle}>
        <Facebook fontSize="large" />
      </IconButton>
      <IconButton component={StyledLineShareButton} url={url || href}>
        <Line fontSize="large" />
      </IconButton>
    </Box>
  )
}

export default SnsLinkBox
