const Au = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="6.93" viewBox="0 0 19.25 6.93">
    <title>Au Icon</title>
    <path
      d="M129.968,121.072a19.852,19.852,0,0,1-2.46,3.2c-.49.461-1.519,1.116-1.1-.429.117-.441,1.358-5.078,1.358-5.078a2.4,2.4,0,0,0-2.545,1.977s-.351,1.312-.5,1.865a3.486,3.486,0,0,1-.421.916,2.609,2.609,0,0,1-1.929,1.29c-.322-.015-.477-.129-.534-.36a2.5,2.5,0,0,1,.116-.912c.052-.187,1.279-4.775,1.279-4.775a2.393,2.393,0,0,0-2.544,1.977l-.605,2.249a2.5,2.5,0,0,1-.318.667c-.389.558-1.079,1.155-1.444,1.155-.26,0-.363-.3-.183-.971l1.359-5.078h-.6a8.559,8.559,0,0,0-4.778,1.081,5.907,5.907,0,0,0-2.369,4.184c0,.536.165,1.66,2.028,1.66a3.268,3.268,0,0,0,2.475-1.173l.056-.065a1.549,1.549,0,0,0,.331.785,1.568,1.568,0,0,0,1.154.452,3.035,3.035,0,0,0,2.265-1.238v0c.114.554.536,1.236,1.987,1.236a3.262,3.262,0,0,0,2.475-1.174c.019-.021.037-.044.056-.065a1.551,1.551,0,0,0,.329.785l.022.022.006.007a1.654,1.654,0,0,0,1.111.424,3.409,3.409,0,0,0,2.373-1.22,31.011,31.011,0,0,0,2.581-3.4Zm-13.487,1.419a3.525,3.525,0,0,1-.451,1.033,2.62,2.62,0,0,1-1.932,1.29c-.594-.028-.657-.38-.415-1.275.826-3.091,2.691-3.791,3.582-3.974Zm8.092,1.962h0"
      transform="translate(-111.744 -118.762)"
      fill="#eb5505"
    />
  </svg>
)

export default Au
