import { UppingImg } from '@/components/atoms'
import { useWebP } from '@/contexts/webp'
import { Box, Typography, styled } from '@mui/material'

const AuditionWrap = styled(Box)({
  position: 'absolute',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
})

const AuditionTypography = styled(Typography)({
  marginTop: '-2px',
  fontSize: '1.2rem',
  color: '#ffffff',
  fontWeight: 'bold',
})

const StyledPicture = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    position: 'absolute',
    display: 'inline-block',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(51, 178, 228, .7)',
    backdropFilter: ' blur(3px)',
    borderRadius: 4,
  },
})

const StyledImg = styled('img')({
  display: 'inline-block',
  width: '3.4rem',
})

export interface DuringAuditionUppingImgProps {
  width: number
  coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt']
  title: Queries.audiobookInfoQuery['contentfulAudiobook']['title']
}

const DuringAuditionUppingImg = ({ width, coverArt, title }: DuringAuditionUppingImgProps) => {
  const { calculatedHeight } = useWebP()
  return (
    <>
      <AuditionWrap width={width} height={calculatedHeight(coverArt, width)}>
        <StyledImg
          src={'/assets/images/audiobook/trial_equalizer.png'}
          srcSet={
            '/assets/images/audiobook/trial_equalizer_2x.png 2x, /assets/images/audiobook/trial_equalizer_3x.png 3x'
          }
          alt={`試聴中-${title}`}
        />
        <AuditionTypography>試聴中</AuditionTypography>
      </AuditionWrap>
      <StyledPicture>
        <UppingImg width={width} coverArt={coverArt} title={title} />
      </StyledPicture>
    </>
  )
}

export default DuringAuditionUppingImg
