import { RegisterEmailAddress } from '@/components/SVG/Icons'
import { Confirm } from '@/components/organisms'
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

export interface EmailOverlayProps {
  open: boolean
  onClose: () => void
}

const EmailOverlay = (props: EmailOverlayProps) => (
  <Confirm {...props}>
    <Box px={2} py={3}>
      <Typography variant="body1" color="textSecondary">
        ご購入の前にdwango IDの作成をお願いいたします。
      </Typography>
    </Box>

    <Divider />

    <List component="div">
      <ListItemButton href={process.env.ACCOUNT_CENTER_URL}>
        <ListItemIcon>
          <RegisterEmailAddress fontSize="large" color="secondary" />
        </ListItemIcon>
        <ListItemText primary="dwango IDを作成する" />
      </ListItemButton>
    </List>
  </Confirm>
)

export default EmailOverlay
