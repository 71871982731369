import { ListItemText, ListItem as MuiListItem, styled } from '@mui/material'

export interface ItemProps {
  primary: string
  secondary: string
}

const StyledListItemText = styled(ListItemText)({
  marginTop: 0,
  marginBottom: 0,

  display: 'block',
  width: '20.4rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '& > span': {
    display: 'inline',
    fontSize: '1.6rem',
  },

  '& > span:first-of-type': {
    marginRight: '0.8rem',
  },
})

const ListItem = ({ primary, secondary }: ItemProps) => (
  <MuiListItem sx={{ p: '0 !important' }}>
    {primary.length > 0 && (
      <StyledListItemText primary={primary} secondary={secondary} secondaryTypographyProps={{ component: 'span' }} />
    )}
    {primary.length <= 0 && (
      <StyledListItemText secondary={secondary} secondaryTypographyProps={{ component: 'span' }} />
    )}
  </MuiListItem>
)

export default ListItem
