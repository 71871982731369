import Audiobook from '@/libs/Audiobook'
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material'
import { Box, styled } from '@mui/material'
import { reduce, throttle } from 'lodash'
import { type MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import SlideItem from './Item'
import SlideItemAsync from './Item/async'

interface SlideListProps {
  books?: Queries.audiobookInfoQuery['contentfulAudiobook'][]
  collectionIds?: string[]
}

const ArrowWrapBox = styled(Box)({
  position: 'absolute',
  zIndex: 10,
  width: '8rem',
  height: '25.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: 'rgba(255,255, 255, 0.7)',
  '& .MuiSvgIcon-fontSizeLarge': {
    fontSize: '4.8rem',
  },
})

const NextArrow = ({ onClick }: { onClick?: MouseEventHandler }) => (
  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
    <ArrowWrapBox right={0} onClick={onClick}>
      <ChevronRightRounded color="inherit" fontSize="large" />
    </ArrowWrapBox>
  </Box>
)

const PrevArrow = ({ onClick }: { onClick?: MouseEventHandler }) => (
  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
    <ArrowWrapBox left={0} onClick={onClick}>
      <ChevronLeftRounded color="inherit" fontSize="large" />
    </ArrowWrapBox>
  </Box>
)

const ITEM_WIDTH = 136

const SlideList = ({ books: originalBooks, collectionIds }: SlideListProps) => {
  const books = originalBooks?.filter(Audiobook.available)
  const scrollRef = useRef<HTMLElement>()
  const parentBoxRef = useRef<HTMLDivElement>()
  const audiobooksBoxRef = useRef<HTMLDivElement>()
  const [showArrow, setShowArrow] = useState(false)
  const [leftArrow, setLeftArrow] = useState(false)
  const [rightArrow, setRightArrow] = useState(false)

  const setRefCB = useCallback((node: HTMLDivElement) => {
    parentBoxRef.current = node
    if (!node) {
      return
    }
    setShowArrow(audiobooksWidth() > node.clientWidth)
    updateArrow()
  }, [])

  const audiobooksWidth = () => {
    if (collectionIds && !audiobooksBoxRef?.current?.childNodes?.length) {
      // id指定のケース（最近見た商品）の場合はjson取得までDOMが生成されず、childNodesがゼロなので近似値で代用する
      return collectionIds.length * ITEM_WIDTH + 16 + 10 + 2 // カードx136px＋左右マージン＋padding2
    }
    // こちらは右マージンが最後の要素に含まれているため右マージンは足さない
    return reduce(audiobooksBoxRef?.current?.childNodes, (w, item: HTMLElement) => w + item.clientWidth, 0) + 16
  }

  const updateArrow = () => {
    if (!scrollRef.current) {
      return
    }
    const { scrollLeft } = scrollRef.current
    setLeftArrow(scrollLeft !== 0)
    setRightArrow(scrollLeft + parentBoxRef.current.clientWidth <= audiobooksWidth() - 10)
  }
  const onScroll = throttle(() => {
    updateArrow()
  }, 250)
  const scrollTo = (n: number) => {
    const elm = scrollRef.current
    elm.scrollTo({ left: Math.max(elm.scrollLeft + n, 0), behavior: 'smooth' })
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const resize = () => {
      if (!scrollRef.current) {
        return
      }
      setShowArrow(audiobooksWidth() > scrollRef.current.clientWidth)
      updateArrow()
    }
    window.addEventListener('resize', resize, false)
    return () => {
      window.removeEventListener('resize', resize, false)
    }
  }, [])

  return (
    <Box display="flex" justifyContent="center" position="relative" ref={setRefCB}>
      {showArrow && leftArrow && <PrevArrow onClick={() => scrollTo(ITEM_WIDTH * -3)} />}
      <ScrollContainer innerRef={scrollRef} onScroll={onScroll}>
        <Box display="flex" px={2} ref={audiobooksBoxRef}>
          {books?.map((book, i) => (
            <SlideItem key={i} index={i} {...book} />
          ))}
          {collectionIds?.map((collectionId, i) => (
            <SlideItemAsync key={i} collectionId={collectionId} />
          ))}
        </Box>
      </ScrollContainer>
      {showArrow && rightArrow && <NextArrow onClick={() => scrollTo(ITEM_WIDTH * 3)} />}
    </Box>
  )
}
export default SlideList
