import theme from '@/theme'
import { Typography, type TypographyProps, styled } from '@mui/material'

interface HeadingH1Props extends TypographyProps {}

const StyledH1 = styled(Typography)({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: '2.4rem 1.6rem',
})

const HeadingH1 = ({ children }: HeadingH1Props) => <StyledH1 variant="h1">{children}</StyledH1>

export default HeadingH1
