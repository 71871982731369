import theme from '@/theme'
import { SvgIcon, type SvgIconProps } from '@mui/material'

interface FacebookProps extends SvgIconProps {}

const Facebook = (props: FacebookProps) => (
  <SvgIcon
    sx={{
      fill: theme.palette.text.primary,
      width: '2.4rem',
      height: '2.4rem',
      '&.MuiSvgIcon-fontSizeLarge': {
        fill: theme.palette.text.secondary,
        width: '3.2rem',
        height: '3.2rem',
      },
    }}
    {...props}
  >
    <svg viewBox="0 0 24 24">
      <title>Facebook Icon</title>
      <g transform="translate(-655 -601)">
        <path
          d="M17.007,0H.993A.993.993,0,
        0,0,0,.993V17.007A.993.993,0,0,0,.993,18H9.614V11.03H7.269V8.313H9.614v-2a3.273,3.273,0,0,1,3.494-3.591,19.252,19.252,0,0,1,2.1.107v2.43H13.766c-1.128,0-1.346.536-1.346,1.322V8.313h2.69l-.35,2.717H12.42V18h4.587A.994.994,0,0,0,18,17.007V.993A.993.993,0,0,0,17.007,0Z"
          transform="translate(658 604)"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default Facebook
