import { ListItem, type Theme, useMediaQuery } from '@mui/material'
import type React from 'react'
import { useEffect, useState } from 'react'

export interface ItemWrapProps {
  dense?: boolean
  children: React.ReactNode
}

const ListWrap = ({ dense, children }: ItemWrapProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [itemWidth, setItemWidth] = useState('30%')

  const handleResize = () => {
    const cardWidth = (num: number) => {
      return `calc( calc(100vw - ${(num + 2) * 16 + 252}px) / ${num})`
    }
    if (window.innerWidth >= 3292) {
      setItemWidth(cardWidth(12))
    } else if (window.innerWidth >= 2282) {
      setItemWidth(cardWidth(8))
    } else if (window.innerWidth >= 1780) {
      setItemWidth(cardWidth(6))
    } else if (window.innerWidth >= 1276) {
      setItemWidth(cardWidth(4))
    } else {
      setItemWidth(cardWidth(3))
    }
  }
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ListItem
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        boxShadow: 'none',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'column',
          width: itemWidth,
          ml: 2,
          mb: 3,
          borderRadius: '4px',
          p: '0 0 1.6rem',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        },
        [theme.breakpoints.down('md')]: {
          p: 2,
        },
      })}
      divider={isMobile}
      dense={dense}
    >
      {children}
    </ListItem>
  )
}

export default ListWrap
