import { CancelRounded } from '@mui/icons-material'
import { styled } from '@mui/material'
import { IconButton, type IconButtonProps } from '@mui/material'

export interface ResetIconButtonProps extends IconButtonProps {}

const StyledIconButton = styled(IconButton)({
  height: '3rem',
  width: '3rem',
  padding: '0.4rem',
  margin: '0',
})

const ResetIconButton = (props: ResetIconButtonProps) => {
  return (
    <StyledIconButton {...props}>
      <CancelRounded fontSize="large" color="secondary" />
    </StyledIconButton>
  )
}

export default ResetIconButton
