import { CenteringImg } from '@/components/atoms'
import { DiscountChip, NewChip, RewardChip } from '@/components/atoms/Chips'
import Audiobook, { findDiscount } from '@/libs/Audiobook'
import theme from '@/theme'
import { Box, Grid, Link, Paper, type SxProps, Typography, styled, useMediaQuery } from '@mui/material'

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: '0.4rem',
  listStyle: 'none',
  '&:last-of-type': {
    paddingRight: '1.6rem',
  },
  [theme.breakpoints.up('md')]: {
    '&:last-of-type': {
      paddingRight: '1.6rem',
    },
  },
}))

const OverflowSx: SxProps = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

export type SlideItemProps = Queries.audiobookInfoQuery['contentfulAudiobook'] & {
  index?: number
}

const SlideItem = (props: SlideItemProps) => {
  const {
    collectionId,
    coverArt,
    title,
    authors,
    startDateTime,
    importedDateTime,
    campaignrequirementpurchase,
    discount,
    index = 0,
  } = props
  const discountTerm = findDiscount(discount)
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const eagerUp = mdUp ? 12 : smUp ? 6 : 3

  return (
    <StyledGrid container item title={title}>
      <Link href={`/audiobook/${collectionId}`} color="inherit">
        <Paper sx={{ height: '100%' }}>
          <Box
            sx={{
              borderRadius: '4px',
              width: '12.8rem',
              bgcolor: 'rgba(0, 0, 0, 0.06)',
              p: 1,
            }}
          >
            {coverArt ? (
              <CenteringImg
                loading={index >= eagerUp ? 'lazy' : 'eager'}
                width={112}
                height={152}
                coverArt={coverArt}
                title={title}
              />
            ) : (
              <Box
                sx={{
                  display: 'block',
                  height: '15.2rem',
                  backgroundColor: 'transparent',
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              width: '12.8rem',
              mt: '0.6rem',
              px: 1,
              pb: 1,
              textAlign: 'left',
            }}
          >
            {Audiobook.hasChip(props) && (
              <Box sx={{ mb: 0.5 }}>
                {Audiobook.hasRewardChip({ campaignrequirementpurchase }) ? (
                  <RewardChip />
                ) : discountTerm ? (
                  <DiscountChip discountLabel={discountTerm.label} />
                ) : Audiobook.hasNewChip({
                    startDateTime,
                    importedDateTime,
                  }) ? (
                  <NewChip />
                ) : null}
              </Box>
            )}
            <Typography variant="h4" sx={OverflowSx}>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={OverflowSx}>
              {authors?.map((a) => a.names.find((n) => n.languageAndScriptCode === 'ja-Hani')?.text).join(' ')}
            </Typography>
          </Box>
        </Paper>
      </Link>
    </StyledGrid>
  )
}

export default SlideItem
