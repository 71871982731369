import { SearchRounded } from '@mui/icons-material'
import { styled } from '@mui/material'
import { IconButton, type IconButtonProps } from '@mui/material'
import React from 'react'

interface SearchIconButtonProps extends IconButtonProps {}

const StyledIconButton = styled(IconButton)({
  padding: '0.4rem',
  margin: '0 0.6rem',
})

const SearchIconButton = (props: SearchIconButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <StyledIconButton {...props} ref={ref}>
    <SearchRounded fontSize="large" />
  </StyledIconButton>
)

export default React.forwardRef(SearchIconButton)
