import { ListItemButton, type ListItemButtonProps } from '@mui/material'
import { useLocation } from '@reach/router'
import { useMemo } from 'react'

interface SelectedListItemButtonProps extends ListItemButtonProps<'a'> {}

const SelectedListItemButton = ({ children, href, ...props }: SelectedListItemButtonProps) => {
  const location = useLocation()
  const selected = location.pathname.split('/').slice(0, 3).join('/') === href.split('/').slice(0, 3).join('/')
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  return useMemo(
    () => (
      <ListItemButton selected={selected} href={href} {...props}>
        {children}
      </ListItemButton>
    ),
    [selected],
  )
}

export default SelectedListItemButton
