import { createStoreContext, useSelector } from '@/hooks/store'
import { type ReactNode, createContext, useContext } from 'react'

const Context = createContext<AppContextProps>(undefined)
export const useApp = () => useContext(Context)
interface AppContextProps {
  useAppDispach: () => {
    setDrawerOpen: (drawerOpen: boolean) => void
  }
  useDrawerOpen: () => boolean
}

interface AppStore {
  drawerOpen: boolean
}

interface AppProviderProps {
  children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const context = createStoreContext<AppStore>(() => ({
    drawerOpen: false,
  }))
  return (
    <Context.Provider
      value={{
        useAppDispach: () => ({
          setDrawerOpen: (drawerOpen: boolean) => context.dispatch((state) => ({ ...state, drawerOpen })),
        }),
        useDrawerOpen: () => useSelector(context, (state: AppStore) => state.drawerOpen),
      }}
    >
      {children}
    </Context.Provider>
  )
}
