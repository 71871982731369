import AudiobookListItem from '@/components/organisms/Audiobook/Item'
import { useAudiobookInfo } from '@/hooks/audiobook'
import type { HitAudioBook } from '@/libs/Algolia'

export interface ItemProps extends HitAudioBook {
  queryID: string
  position: number
}

const Item = ({ objectID, queryID, position }: ItemProps) => {
  const audiobook = useAudiobookInfo(objectID)

  return audiobook.collectionId ? <AudiobookListItem {...audiobook} queryID={queryID} position={position} /> : <></>
}

export default Item
