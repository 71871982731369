import { SelectedListItemButton } from '@/components/atoms/'
import { ExpandLessRounded, ExpandMoreRounded, SearchRounded } from '@mui/icons-material'
import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import type React from 'react'
import { useEffect, useState } from 'react'
export interface SingleCategoryMenuProps {
  parentCategory: Queries.findAllContentsQueryQuery['allContentfulParentCategory']['nodes'][0]
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const SingleCategoryMenu = ({ parentCategory: { name, slug, category }, onClick }: SingleCategoryMenuProps) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setOpen(location.pathname.includes(slug) || category.some((c) => location.pathname.includes(c.slug)))
  }, [location.pathname])

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton component="li" onClick={handleClick}>
        <ListItemIcon>
          <SearchRounded color="secondary" fontSize="large" />
        </ListItemIcon>
        <ListItemText primary={name} />
        {open ? <ExpandLessRounded fontSize="large" /> : <ExpandMoreRounded fontSize="large" />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SelectedListItemButton
          key={slug}
          href={`/parent_category/${slug}/1/`}
          onClick={(event) => {
            onClick?.(event)
            navigate(`/parent_category/${slug}/1`)
          }}
          component="li"
        >
          <ListItemText inset primary="すべて" />
        </SelectedListItemButton>
        {[...category]
          .sort((a, b) => (b.weight || 0) - (a.weight || 0))
          .map((category) => (
            <SelectedListItemButton
              key={category.slug}
              href={`/category/${category.slug}/1/`}
              onClick={(event) => {
                onClick?.(event)
                navigate(`/category/${category.slug}/1`)
              }}
              component="li"
            >
              <ListItemText inset primary={category.name} />
            </SelectedListItemButton>
          ))}
      </Collapse>
    </>
  )
}

export default SingleCategoryMenu
