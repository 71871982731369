import FavoriteAnimatedIcon from '@/components/atoms/FavoriteAnimatedIcon'
import { IconButton, type IconButtonProps } from '@mui/material'

interface FavoriteIconButtonProps extends IconButtonProps {
  isFavorite: boolean
}

const FavoriteIconButton = ({ isFavorite, ...rest }: FavoriteIconButtonProps): JSX.Element => {
  return (
    <IconButton {...rest}>
      <FavoriteAnimatedIcon isFavorite={isFavorite} />
    </IconButton>
  )
}

export default FavoriteIconButton
