import { AddPoint } from '@/components/SVG/Icons'
import { IconButton, type IconButtonProps } from '@mui/material'

interface AddPointIconButtonProps extends IconButtonProps {}

const AddPointIconButton = (props: AddPointIconButtonProps): JSX.Element => {
  return (
    <IconButton {...props} size="large">
      <AddPoint fontSize="large" />
    </IconButton>
  )
}

export default AddPointIconButton
