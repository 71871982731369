import { Script, graphql, useStaticQuery } from 'gatsby'

export const shortDescription = (str: string) =>
  str ? `${str.replace(/(<("[^"]*"|'[^']*'|[^'">])*>|\n)/g, '').slice(0, 120)}...` : ''

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        organization
        siteUrl
        defaultTitle
        titleTemplate
        logoImage
        ogpImage
      }
    }
  }
`

interface SeoProps {
  title?: string
  description?: string
  ogpImage?: string
  ogpDescription?: string
  ogpPath?: string
  jsonLd?: (siteMetadata: Queries.SiteSiteMetadata) => Record<string, unknown>
}

const SEO = ({ title, description, ogpImage, ogpDescription, ogpPath, jsonLd }: SeoProps) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)
  const { title: siteTitle, siteUrl, defaultTitle, titleTemplate, description: defaultDescription } = siteMetadata

  title = title ? `${title}${titleTemplate}` : defaultTitle
  description = description ? `${description}${defaultDescription}` : defaultDescription
  const ogpUrl = ogpPath ? `${siteUrl}${ogpPath}` : siteUrl
  const ogpImageSize = ogpImage ? 'summary_large_image' : 'summary'
  ogpImage = ogpImage ?? siteMetadata.ogpImage
  ogpDescription = ogpDescription ? `${ogpDescription}${defaultDescription}` : defaultDescription

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content={siteTitle} />
      <meta name="og:image" content={ogpImage} />
      <meta name="og:title" content={title} />
      <meta name="og:url" content={ogpUrl} />
      <meta name="og:description" content={ogpDescription} />
      <meta name="twitter:card" content={ogpImageSize} />
      {jsonLd && <Script type="application/ld+json">{JSON.stringify(jsonLd(siteMetadata))}</Script>}
    </>
  )
}

export default SEO
