import { CenteringImg, UppingImg } from '@/components/atoms'
import { ListItem, ListItemAvatar, ListWrap } from '@/components/atoms/Audiobook'
import PurchaseDetailsButton from '@/components/organisms/Library/PurchaseDetailsButton'
import type { BillItem } from '@/contexts/connect'
import { useAudiobookInfo } from '@/hooks/audiobook'
import Audiobook from '@/libs/Audiobook'
import { Box, Button, Link, List, ListItem as MuiListItem, Typography, styled } from '@mui/material'
import type React from 'react'

const ChildList = styled(List)(({ theme }) => ({
  padding: 0,
  '& > *': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& > h2': {
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    marginBottom: '0.8rem',
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    marginTop: '.8rem',
  },
}))

const PlayButton = styled(Button)(({ theme }) => ({
  width: '8.8rem',
  fontWeight: 'bold',
  color: '#000000',
  '& svg': {
    color: '#000000',
  },
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}))

const AvailabledLink = ({
  available,
  href,
  children,
}: {
  available: boolean
  href: string
  children: React.ReactNode
}) => {
  if (available) {
    return <Link href={href}>{children}</Link>
  }
  return <>{children}</>
}

export interface ItemProps extends BillItem {}

const Item = ({ contentId, displayName, price, paid }: ItemProps) => {
  const audiobook = useAudiobookInfo(contentId)
  const { coverArt, authors, narrators } = audiobook
  const available = Audiobook.available(audiobook)

  return (
    <ListWrap>
      <ListItemAvatar>
        <AvailabledLink href={`/audiobook/${contentId}`} available={available}>
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              width: { md: 'none', xs: 80 },
            }}
          >
            <UppingImg width={80} coverArt={coverArt} title={displayName} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' }, height: 236, py: 2 }}>
            <CenteringImg width={204} height={204} coverArt={coverArt} title={displayName} />
          </Box>
        </AvailabledLink>
      </ListItemAvatar>

      <Box sx={{ width: { md: '100%' }, px: { md: 2 } }}>
        <ChildList>
          {available ? (
            <MuiListItem
              sx={{
                mb: 1,
                p: 0,
                lineHeight: 1.5,
                width: { md: '100%' },
              }}
              component={Link}
              color="textPrimary"
              href={`/audiobook/${contentId}`}
            >
              <Typography variant="h2" component="h2">
                {displayName}
              </Typography>
            </MuiListItem>
          ) : (
            <MuiListItem
              sx={{
                mb: 1,
                p: 0,
                width: { md: '100%' },
              }}
            >
              <Typography variant="h2" component="h2">
                {displayName}
              </Typography>
            </MuiListItem>
          )}

          {authors?.length > 0 && (
            <ListItem
              primary=""
              secondary={authors
                .map((author) => author.names.find((name) => name.languageAndScriptCode === 'ja-Hani').text)
                .join(' ')}
            />
          )}

          <Box sx={{ width: { md: '100%' }, display: 'flex', mt: 2 }}>
            <Box sx={{ pr: 0.5, width: { md: '50%' } }}>
              <PurchaseDetailsButton
                contentId={contentId}
                price={price}
                paid={paid}
                displayName={displayName}
                authors={authors}
                narrators={narrators}
              />
            </Box>
            <Box sx={{ pl: 0.5, width: { md: '50%' } }}>
              <PlayButton href={`/audiobook/${contentId}/player`}>再生する</PlayButton>
            </Box>
          </Box>
        </ChildList>
      </Box>
    </ListWrap>
  )
}

export default Item
