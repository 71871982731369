import theme from '@/theme'
import { Box } from '@mui/material'
import type { ReactNode } from 'react'

interface RewardHeaderBoxProps {
  children?: ReactNode
}

const RewardHeaderBox = ({ children }: RewardHeaderBoxProps) => (
  <Box
    sx={{
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '-0.8rem',
        left: '3.6rem',
        borderStyle: 'solid',
        borderTop: `0.8rem solid ${theme.palette.secondary.main}`,
        borderRight: '0.8rem solid transparent',
        borderLeft: '0.8rem solid transparent',
        borderBottom: 0,
      },
    }}
  >
    {children}
  </Box>
)

export default RewardHeaderBox
