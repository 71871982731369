import { Typography, type TypographyProps } from '@mui/material'

export interface PreProps extends TypographyProps<'pre'> {}

const Pre = ({ children, ...rest }: PreProps) => (
  <Typography variant="body1" component="pre" {...rest} sx={{ ...rest.sx, textAlign: 'left', whiteSpace: 'pre-wrap' }}>
    {children}
  </Typography>
)

export default Pre
