import { Box, Chip } from '@mui/material'

export interface ChipsProps {
  category?: Queries.Maybe<Array<Queries.Maybe<Pick<Queries.ContentfulCategory, 'slug' | 'id' | 'name'>>>>
  currentSlug?: string
  parentCategory?: Pick<Queries.ContentfulParentCategory, 'id' | 'name' | 'slug'>
}

const Chips = ({ currentSlug, parentCategory, category }: ChipsProps) => {
  return (
    <Box display="flex" flexWrap="wrap" px={2} pt={2} pb={0.5}>
      <Box mr={1.5} mb={1.5}>
        {parentCategory ? (
          <Chip label="すべて" clickable component="a" href={`/parent_category/${parentCategory.slug}/1`} />
        ) : (
          <Chip label="すべて" color="primary" />
        )}
      </Box>
      {category.map(({ id, name, slug }: Pick<Queries.ContentfulCategory, 'slug' | 'id' | 'name'>) => (
        <Box key={id} mr={1.5} mb={1.5}>
          {slug === currentSlug ? (
            <Chip label={name} color="primary" />
          ) : (
            <Chip label={name} component="a" clickable href={`/category/${slug}/1`} />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default Chips
