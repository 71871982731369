import { List as MuiList, type ListProps as MuiListProps, styled } from '@mui/material'

const StyledList = styled(MuiList)({
  '& .MuiListItem-gutters': {
    padding: 0,
  },
})

interface ListProps extends MuiListProps {}

const List = ({ children, ...rest }: ListProps) => <StyledList {...rest}>{children} </StyledList>

export default List
