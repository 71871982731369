import { useWebP } from '@/contexts/webp'
import { Box, Link, styled } from '@mui/material'
import { useEffect, useState } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { sample } from 'lodash'

const StyledImg = styled('img')(({ theme }) => ({
  width: '100%',
  display: 'block',
  paddingLeft: '2rem',
  paddingRight: '2rem',

  [theme.breakpoints.up('md')]: {
    width: '580px',
    height: '160px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}))

const RandomBanner = () => {
  const { fm } = useWebP()
  const [banner, setBanner] = useState(null)

  const data: Queries.findBannerQuery = useStaticQuery(graphql`
    query findBanner {
      contentfulBannerList(slug: { eq: "random-banner-on-book-detail" }) {
        slug
        banners {
          id
          image {
            title
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
              contentType
            }
          }
          linkUrl
        }
      }
    }
  `)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setBanner(sample(data.contentfulBannerList?.banners))
  }, [])

  return (
    <>
      {banner && (
        <Box display="flex" justifyContent="center" mb={4}>
          <Link href={banner.linkUrl}>
            <StyledImg loading="lazy" src={`${banner.image.file.url}?fm=${fm()}`} alt={banner.image.title} />
          </Link>
        </Box>
      )}
    </>
  )
}

export default RandomBanner
