import { HeadingH1 } from '@/components/atoms'
import { useWebP } from '@/contexts/webp'
import { Box, styled } from '@mui/material'

const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
})

const BackgroundImageBox = styled(Box)({
  backgroundSize: 'cover',
  zIndex: 'auto',
  overflow: 'hidden',
  position: 'relative',

  '&:before': {
    content: '""',
    filter: 'blur(20px)',
    position: 'absolute',
    width: '130%',
    height: '130%',
    top: '-15%',
    left: '-15%',
    backgroundImage: 'inherit',
    backgroundSize: 'cover',
  },
})

interface PageHeaderProps {
  pageTitle: string
  headerImage: Queries.findCampaignRequirementPurchaseQuery['contentfulCampaignRequirementPurchase']['headerImage']
}

const PageHeader = ({ pageTitle, headerImage }: PageHeaderProps) => {
  const { headSrc, headSrcSet } = useWebP()
  const coverArt = headerImage as Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt']

  return (
    <>
      <HeadingH1>{pageTitle}</HeadingH1>
      {headerImage && (
        <BackgroundImageBox
          style={{
            backgroundImage: `url(${coverArt.file.url}?w=${452 / 2}&h=${
              320 / 2
            }&q=50&fm=jpg` /* 二重ダウンロード防止の為 headSrc() のhydrated時のものと合わせている */,
          }}
        >
          <Box
            width={{ xs: '100%', md: 452 }}
            height={{ md: 320 }}
            maxWidth={{ xs: 360, md: 452 }}
            margin="auto"
            position="relative"
          >
            <StyledImg src={headSrc(452, 320, coverArt)} srcSet={headSrcSet(452, 320, coverArt)} alt={pageTitle} />
          </Box>
        </BackgroundImageBox>
      )}
    </>
  )
}

export default PageHeader
